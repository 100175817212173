@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
div.loading-background-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.loading-background-container svg.spin {
  animation: spinoffPulse 1.1s infinite linear;
  -webkit-animation: spinoffPulse 1.1s infinite linear;
}

@keyframes spinoffPulse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes spinoffPulse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotatecircle {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@-webkit-keyframes rotatecircle {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}



